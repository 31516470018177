import request from '@/router/axios';
import {businessUrl} from '@/config/env'
export const list = (name,code,pageNo,pageSize) => {
    console.log(name,code,pageNo,pageSize, 'name,code,pageNo,pageSize')
    return request({
        url: businessUrl + "codeRule/selectPage",
        method: "post",
        data: {
            name:name,
            code:code,
            pageNo:pageNo,
            pageSize:pageSize

        }
    })
}
export const edit = (id,name,code,rule,status) => {
    return request({
        url: businessUrl + "codeRule/updateCodeRule",
        method: "post",
        data: {
            id,
            name,
            code,
            rule,
            status
        }
    })
}
// 删除
export const remove = (id) => {
    return request({
        url: businessUrl + "codeRule/deleteCodeRule",
        method: "delete",
        params: {
            id
        }
    })
}
export const treeList = () => {
    return request({
        url: businessUrl + "dictCategory/selectAll"
    })
}
// 添加规则
export const addCodeRule = (name,code,rule) => {
    return request({
        url: businessUrl + "codeRule/addCodeRule",
        method: "post",
        data: {
            name:name,
            code:code,
            rule:rule

        }
    })
}
export const generateRule = (id) => {
    return request({
        url: businessUrl + "codeRule/getStringByRule",
        method: "post",
        params: {
            id
        }
    })
}
export const getBrowse = (code) => {
    return request({
        url: businessUrl + "codeRule/selectModelString",
        method: "get",
        params: {
            code
        }
    })
}
