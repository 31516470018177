var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "basic-container",
        [
          _vm.addVisible
            ? _c(
                "el-dialog",
                {
                  attrs: {
                    title: _vm.$t("Add"),
                    fullscreen: "",
                    visible: _vm.addVisible,
                    width: "70%",
                    "append-to-body": true
                  },
                  on: {
                    "update:visible": function($event) {
                      _vm.addVisible = $event
                    }
                  }
                },
                [
                  _c(
                    "el-collapse",
                    {
                      model: {
                        value: _vm.activeName,
                        callback: function($$v) {
                          _vm.activeName = $$v
                        },
                        expression: "activeName"
                      }
                    },
                    [
                      _c(
                        "el-collapse-item",
                        { attrs: { title: "规则基础信息", name: "1" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "150px", marginLeft: "10px" },
                            attrs: {
                              placeholder: "请输入规则名称",
                              size: "small"
                            },
                            model: {
                              value: _vm.name,
                              callback: function($$v) {
                                _vm.name = $$v
                              },
                              expression: "name"
                            }
                          }),
                          _c("el-input", {
                            staticStyle: { width: "150px", marginLeft: "10px" },
                            attrs: {
                              placeholder: "请输入规则编码",
                              size: "small"
                            },
                            model: {
                              value: _vm.code,
                              callback: function($$v) {
                                _vm.code = $$v
                              },
                              expression: "code"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-collapse-item",
                        { attrs: { title: "格式定义", name: "2" } },
                        [
                          _c(
                            "avue-crud",
                            {
                              ref: "crud",
                              attrs: {
                                option: _vm.tableOptions,
                                data: _vm.tableDatas,
                                "table-loading": _vm.tableLoading
                              },
                              on: { "row-del": _vm.showHandleDel }
                            },
                            [
                              _c(
                                "template",
                                { slot: "menuLeft" },
                                [
                                  _c(
                                    "el-popover",
                                    {
                                      attrs: {
                                        placement: "bottom-start",
                                        title: "规则操作手册：",
                                        width: "50%",
                                        trigger: "hover",
                                        content: ""
                                      },
                                      on: { show: _vm.showBro }
                                    },
                                    [
                                      _c("div", {
                                        staticClass: "popover-content",
                                        domProps: {
                                          innerHTML: _vm._s(_vm.falg)
                                        }
                                      }),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            slot: "reference",
                                            size: "small",
                                            type: "primary"
                                          },
                                          slot: "reference"
                                        },
                                        [_vm._v("规则说明")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "template",
                                { slot: "menuRight" },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: {
                                        width: "150px !important",
                                        "margin-right": "5px",
                                        marginLeft: "10px"
                                      },
                                      attrs: {
                                        placeholder: "类型",
                                        size: "small",
                                        hide: "true",
                                        filterable: ""
                                      },
                                      on: { change: _vm.typeChange },
                                      model: {
                                        value: _vm.type,
                                        callback: function($$v) {
                                          _vm.type = $$v
                                        },
                                        expression: "type"
                                      }
                                    },
                                    _vm._l(_vm.typeList, function(item) {
                                      return _c("el-option", {
                                        key: item.value,
                                        attrs: {
                                          label: item.name,
                                          value: item.id
                                        }
                                      })
                                    }),
                                    1
                                  ),
                                  _c("el-input", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.numShow,
                                        expression: "numShow"
                                      }
                                    ],
                                    staticStyle: {
                                      width: "150px",
                                      marginLeft: "10px"
                                    },
                                    attrs: {
                                      type: "number",
                                      placeholder: "请输入总位数",
                                      size: "small"
                                    },
                                    model: {
                                      value: _vm.num,
                                      callback: function($$v) {
                                        _vm.num = $$v
                                      },
                                      expression: "num"
                                    }
                                  }),
                                  _c(
                                    "el-select",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.dataShow,
                                          expression: "dataShow"
                                        }
                                      ],
                                      staticStyle: {
                                        width: "150px !important",
                                        "margin-left": "10px"
                                      },
                                      attrs: {
                                        placeholder: "时间格式",
                                        size: "small",
                                        filterable: ""
                                      },
                                      model: {
                                        value: _vm.data,
                                        callback: function($$v) {
                                          _vm.data = $$v
                                        },
                                        expression: "data"
                                      }
                                    },
                                    _vm._l(_vm.dataList, function(item) {
                                      return _c("el-option", {
                                        key: item.value,
                                        attrs: {
                                          label: item.name,
                                          value: item.name
                                        }
                                      })
                                    }),
                                    1
                                  ),
                                  _c("el-input", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.prefixShow,
                                        expression: "prefixShow"
                                      }
                                    ],
                                    staticStyle: {
                                      width: "150px",
                                      marginLeft: "10px"
                                    },
                                    attrs: {
                                      placeholder: "请输入前缀",
                                      size: "small"
                                    },
                                    model: {
                                      value: _vm.prefix,
                                      callback: function($$v) {
                                        _vm.prefix = $$v
                                      },
                                      expression: "prefix"
                                    }
                                  }),
                                  _c("el-input", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.suffixShow,
                                        expression: "suffixShow"
                                      }
                                    ],
                                    staticStyle: {
                                      width: "150px",
                                      marginLeft: "10px"
                                    },
                                    attrs: {
                                      placeholder: "请输入后缀",
                                      size: "small"
                                    },
                                    model: {
                                      value: _vm.suffix,
                                      callback: function($$v) {
                                        _vm.suffix = $$v
                                      },
                                      expression: "suffix"
                                    }
                                  }),
                                  _c("el-input", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.spacerShow,
                                        expression: "spacerShow"
                                      }
                                    ],
                                    staticStyle: {
                                      width: "150px",
                                      marginLeft: "10px"
                                    },
                                    attrs: {
                                      placeholder: "请输入间隔数",
                                      size: "small"
                                    },
                                    model: {
                                      value: _vm.Spacer,
                                      callback: function($$v) {
                                        _vm.Spacer = $$v
                                      },
                                      expression: "Spacer"
                                    }
                                  }),
                                  _c("el-input", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.separatorShow,
                                        expression: "separatorShow"
                                      }
                                    ],
                                    staticStyle: {
                                      width: "150px",
                                      marginLeft: "10px"
                                    },
                                    attrs: {
                                      placeholder: "请输入分隔符",
                                      size: "small"
                                    },
                                    model: {
                                      value: _vm.Separator,
                                      callback: function($$v) {
                                        _vm.Separator = $$v
                                      },
                                      expression: "Separator"
                                    }
                                  }),
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: { marginLeft: "20px" },
                                      attrs: { type: "primary", size: "small" },
                                      on: { click: _vm.save }
                                    },
                                    [_vm._v("添加")]
                                  )
                                ],
                                1
                              )
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "p",
                    {
                      staticClass: "dialog-footer",
                      staticStyle: { "margin-top": "-30px" },
                      attrs: { slot: "footer" },
                      slot: "footer"
                    },
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function($event) {
                              _vm.addVisible = false
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("Cancel")))]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.addConfirm }
                        },
                        [_vm._v(_vm._s(_vm.$t("Yes")))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "avue-crud",
            {
              ref: "crud",
              attrs: {
                option: _vm.tableOption,
                data: _vm.tableData,
                "table-loading": _vm.tableLoading,
                page: _vm.page
              },
              on: {
                "current-change": _vm.currentChange,
                "size-change": _vm.sizeChange,
                "selection-change": _vm.selectionChange,
                "refresh-change": _vm.refreshChange,
                "row-save": _vm.handleSave,
                "row-update": _vm.handleUpdate,
                "row-del": _vm.handleDel
              },
              scopedSlots: _vm._u([
                {
                  key: "status",
                  fn: function(scope) {
                    return [
                      _c("el-switch", {
                        attrs: { "inactive-value": 1, "active-value": 0 },
                        on: {
                          change: function($event) {
                            return _vm.change(scope.row)
                          }
                        },
                        model: {
                          value: scope.row.status,
                          callback: function($$v) {
                            _vm.$set(scope.row, "status", $$v)
                          },
                          expression: "scope.row.status"
                        }
                      })
                    ]
                  }
                },
                {
                  key: "menu",
                  fn: function(scope) {
                    return [
                      _c(
                        "el-button",
                        {
                          staticStyle: { height: "10px" },
                          attrs: {
                            icon: "el-icon-data-analysis",
                            size: "small",
                            type: scope.type
                          },
                          on: {
                            click: function($event) {
                              return _vm.goReport(scope.row)
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("OPERATIONTYPE1")))]
                      ),
                      _c(
                        "el-popover",
                        {
                          staticStyle: { "margin-left": "10px" },
                          attrs: {
                            placement: "left-start",
                            title: "规则示例",
                            width: "200",
                            trigger: "hover",
                            content: _vm.flag
                          },
                          on: {
                            show: function($event) {
                              return _vm.showBrowse(scope.row)
                            }
                          }
                        },
                        [
                          _c("div", {
                            staticClass: "popover-content",
                            domProps: { innerHTML: _vm._s(_vm.flag) }
                          }),
                          _c(
                            "el-button",
                            {
                              staticStyle: { height: "10px" },
                              attrs: {
                                slot: "reference",
                                icon: "el-icon-data-analysis",
                                size: "small",
                                type: scope.type
                              },
                              on: {
                                click: function($event) {
                                  return _vm.goReport(scope.row)
                                }
                              },
                              slot: "reference"
                            },
                            [_vm._v("浏览")]
                          )
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [
              _c(
                "template",
                { slot: "menuLeft" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.addBtn }
                    },
                    [_vm._v(_vm._s(_vm.$t("ADD")))]
                  )
                ],
                1
              ),
              _c(
                "template",
                { slot: "menuRight" },
                [
                  _c("el-input", {
                    staticStyle: {
                      display: "inlie-block",
                      width: "200px",
                      marginRight: "5px"
                    },
                    attrs: { placeholder: _vm.$t("Rule Name"), size: "small" },
                    model: {
                      value: _vm.nameOrIdLike,
                      callback: function($$v) {
                        _vm.nameOrIdLike = $$v
                      },
                      expression: "nameOrIdLike"
                    }
                  }),
                  _c("el-input", {
                    staticStyle: {
                      display: "inlie-block",
                      width: "200px",
                      marginRight: "5px"
                    },
                    attrs: { placeholder: _vm.$t("Rule Code"), size: "small" },
                    model: {
                      value: _vm.codeOrIdLike,
                      callback: function($$v) {
                        _vm.codeOrIdLike = $$v
                      },
                      expression: "codeOrIdLike"
                    }
                  }),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.search }
                    },
                    [_vm._v(_vm._s(_vm.$t("SEARCH")))]
                  )
                ],
                1
              )
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }